img,
input[type='image'],
video,
embed,
iframe,
marquee,
object,
table {
  aspect-ratio: attr(width) / attr(height);
}

// https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
.h-ios {
  // height: 100vh !important;
  // height: 100% !important;
  // min-height: 100% !important;
  height: 100vh !important;
  height: 100dvh !important;
  min-height: 100dvh !important;
}

.w-ios {
  width: 100vw !important;
  width: 100dvw !important;
  min-width: 100dvw !important;
}

// for editor

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



.editor-preview ul {
  padding-left: 0.5rem;
  padding-left: 0rem !important;
  /* line-height: 2.1rem; */
  list-style: initial;
  list-style-position: inside;
  list-style-image: initial;
  list-style-type: '- ';
}

.editor-preview li {
  list-style: initial;
  list-style-position: inside;
  list-style-image: initial;
  list-style-type: '- ';
}

// for scroll bar like ios

.scrollbar {
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.shadow-box-scroll {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

// fall back for svg icon fontawesome while it not load completed

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  display: var(--fa-display, inline-block);
  height: 1em;
  vertical-align: -0.125em;
}

// for dialog
.ngneat-dialog-content {
  justify-content: center;
  align-items: center;
  width: initial;
}

// for mobile login
.dialog-no-padding .ngneat-dialog-backdrop {
  padding: 0;
}

.dialog-receive-coin .ngneat-close-dialog {
  display: none;
}

.dialog-quote .ngneat-dialog-content {
  width: 100%;
}

.flex-60 {
  flex: 1 1 calc(90vh - 60px);
}

.h--120 {
  max-height: calc(90vh - 120px);
}
