// @font-face {
//   font-display: swap; // Uses a system font until font is ready. Swaps out font.
//   font-family: 'GeorgiaPro';
//   src: url('/frontend-assets/fonts/GeorgiaPro/SVN-Georgia-Pro-Regular.otf') format('truetype');
// }

// SYSTEM FONT

// @font-face {
//   font-display: swap; // Uses a system font until font is ready. Swaps out font.
//   font-family: 'Nunito';
//   src: url('/frontend-assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-display: swap; // Uses a system font until font is ready. Swaps out font.
//   font-family: 'Roboto';
//   src: url('/frontend-assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
// }

// @font-face {
//   font-display: swap; // Uses a system font until font is ready. Swaps out font.
//   font-family: 'PaytoneOne';
//   src: url('/frontend-assets/fonts/PaytoneOne/PaytoneOne-Regular.ttf') format('truetype');
// }

// USAGE

body > * {
  // font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

.font-default {
  font-family: arial, helvetica, sans-serif !important;
}

.georgiaPro {
  font-family: 'GeorgiaPro', 'Times New Roman', serif !important;
}
